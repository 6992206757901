import React from "react";
import { withRouter, match } from "react-router-dom";
import { connect } from "react-redux";
import { Data, isLoaded } from "../../framework/data";
import { LeagueWithCurrentUserAssignment, LeagueStatus } from "../../store/leagues/models";
import { Player } from "../../store/players/models";
import { AppState } from "../../store/reducer";
import { usersAssignedLeague } from "../../store/leagues/selectors";
import { currentPlayer } from "../../store/players/selectors";
import { Dispatch } from "redux";
import { loadLeagueDetails } from "../../store/leagues/actions";
import {
  getPlayersMatches,
  confirmMatch,
  cancelMatch,
  submitMatchResult
} from "../../store/matches/actions";
import MatchesTable from "./MatchesTable";
import { MatchHeader, MatchStatus, Set } from "../../store/matches/models";
import { IconButton, Tooltip, Button, Popover } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import PostAddIcon from "@material-ui/icons/PostAdd";
import MatchResultDialog from "./MatchResultDialog";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import logger from "../../framework/logger";
import LeaguePlayerView from "../leagues/LeaguePlayerView";
import { Club } from "../../store/clubs/models";
import { useAuth0 } from "@auth0/auth0-react";
import CancelConfirmationDialog from "./CancelConfirmation";

interface MyMatchesPageProps {
  league: Data<LeagueWithCurrentUserAssignment>;
  player: Data<Player>;
  clubs: Data<Club[]>;
  loadLeagueDetails: (year: string, id: string) => void;
  loadPlayersMatches: (
    year: string,
    id: string,
    skip: number,
    take: number,
    token: string
  ) => void;
  match: match<{ year: string; id: string }>;
  matches: Data<MatchHeader[]>;
  confirmMatch: (
    match: MatchHeader,
    league: LeagueWithCurrentUserAssignment,
    skip: number,
    take: number,
    token: string
  ) => void;
  cancelMatch: (
    match: MatchHeader,
    league: LeagueWithCurrentUserAssignment,
    skip: number,
    take: number,
    token: string
  ) => void;
  submitResult: (
    match: MatchHeader,
    league: LeagueWithCurrentUserAssignment,
    sets: Set[],
    skip: number,
    take: number,
    token: string
  ) => void;
}

const MyMatchesPage: React.FC<MyMatchesPageProps> = ({
  league,
  player,
  loadLeagueDetails,
  match,
  loadPlayersMatches,
  matches,
  confirmMatch,
  cancelMatch,
  submitResult,
  clubs
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = React.useState<number>();
  const [pageSize, setPageSize] = React.useState<number>();

  const [matchResultDialogOpen, setMatchResultDialogOpen] = React.useState<
    boolean
  >(false);

  const [matchCancelDialogOpen, setMatchCancelDialogOpen] =
    React.useState<boolean>(false);

  const [currentMatch, setCurrentMatch] = React.useState<MatchHeader>();
  const [playerInView, setPlayerInView] = React.useState<{
    el: HTMLElement;
    playerId: string;
  } | null>(null);

  const handleMatchResult = async (success: boolean, sets: Set[]) => {
    if (success && league.type === "LOADED" && currentMatch) {
      const token = await getAccessTokenSilently();
      logger.logEvent(`Submitting match result for ${currentMatch.id}`, { match: JSON.stringify(currentMatch), sets: JSON.stringify(sets) });
      submitResult(
        currentMatch,
        league.value,
        sets,
        (page || 0) * (pageSize || 25),
        pageSize || 25,
        token
      );
    }
    setMatchResultDialogOpen(false);
  };

  const handleMatchCancelled = async (confirmed: boolean) => {
    if(confirmed && league.type === "LOADED" && currentMatch) {
      const token = await getAccessTokenSilently();
      cancelMatch(
        currentMatch,
        league.value,
        (page || 0) * (pageSize || 25),
        pageSize || 25,
        token
      );
    }

    setMatchCancelDialogOpen(false);
  }

  const handleHelpRequested = () => {
    window.location.assign(`mailto:support@smarttennis.pl?subject=Wynik%20meczu%20${currentMatch?.id}&body=Proszę%20podać%20wynik%20meczu`);
  }

  const getPlayerDetails = (playerId: string, target: HTMLElement) => {
    setPlayerInView({ el: target, playerId });
  };

  React.useEffect(() => {
    var id = match.params.id;
    var year = match.params.year;

    if (!isLoaded(league)) {
      loadLeagueDetails(year, id);
    }

    if (league.type === "LOADED" && player.type === "LOADED") {
      getAccessTokenSilently().then((token: string) => {
        loadPlayersMatches(league.value.year, league.value.id, 0, 25, token);
      });
    }
  }, [
    match.params.id,
    match.params.year,
    loadLeagueDetails,
    league,
    loadPlayersMatches
  ]);

  return (
    <>

      {matches.type === "LOADED" &&
        league.type === "LOADED" &&
        player.type === "LOADED" ? (
          <>
            <MatchResultDialog
              open={matchResultDialogOpen}
              firstPlayer={currentMatch?.firstPlayer}
              secondPlayer={currentMatch?.secondPlayer}
              onClosed={(success, sets) => handleMatchResult(success, sets)}
              onHelpRequested={() => handleHelpRequested()}
            ></MatchResultDialog>
            <CancelConfirmationDialog
              open={matchCancelDialogOpen}
              onClosed={(confirmed) => handleMatchCancelled(confirmed)}>
            </CancelConfirmationDialog>
            <Popover
              open={Boolean(playerInView?.el)}
              anchorEl={playerInView?.el}
              onClose={() => setPlayerInView(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {!!playerInView ? (
                <LeaguePlayerView
                  playerId={playerInView?.playerId}
                  leagueId={league.value.id}
                  leagueYear={league.value.year}
                  clubs={clubs}
                ></LeaguePlayerView>
              ) : (
                  <></>
                )}
            </Popover>
            <MatchesTable
              matches={matches.value}
              currentPlayer={player.value}
              onLoadData={async (page, pageSize) => {
                const token = await getAccessTokenSilently();
                const skip = page * pageSize;
                const take = pageSize;
                loadPlayersMatches(
                  league.value.year,
                  league.value.id,
                  skip,
                  take,
                  token
                );
                setPage(page);
                setPageSize(pageSize);
              }}
              page={page || 0}
              pageSize={pageSize || 25}
              actions={m => {
                const firstAction =
                  league.value.status !== LeagueStatus.Finished &&
                    m.status === MatchStatus.New &&
                    m.createdBy &&
                    m.createdBy.id !== player.value.id &&
                    (m.firstPlayer.id === player.value.id ||
                      m.secondPlayer.id === player.value.id) ? (
                      <Tooltip
                        title="Potwierdź, że jesteś umówiony na ten mecz. Zablokowane, jeśli próbujesz zatwierdzić mecz po dacie jest odbycia."
                        key="1"
                      >
                        <IconButton
                          size="small"
                          disabled={m.date < new Date()}
                          onClick={async () => {
                            const token = await getAccessTokenSilently();
                            logger.logEvent(`Confirming match ${m.id}`, { match: m });
                            confirmMatch(
                              m,
                              league.value,
                              (page || 0) * (pageSize || 25),
                              pageSize || 25,
                              token
                            );
                          }}
                        >
                          <CheckIcon></CheckIcon>
                        </IconButton>
                      </Tooltip>
                    ) : undefined;

                const secondAction =
                  league.value.status !== LeagueStatus.Finished &&
                    (m.status === MatchStatus.New || m.status === MatchStatus.Confirmed) &&
                    (m.firstPlayer.id === player.value.id ||
                      m.secondPlayer.id === player.value.id) ? (
                      <Tooltip title="Anuluj mecz" key="2">
                        <IconButton
                          size="small"
                          onClick={async () => {
                            const token = await getAccessTokenSilently();
                            logger.logEvent(`Cancelling match ${m.id}`, { match: m });
                            if (((new Date().getTime() - m.date.getTime()) / (1000 * 60 * 60)) < 24) {
                              setCurrentMatch(m);
                              setMatchCancelDialogOpen(true);
                            } else {
                              cancelMatch(
                                m,
                                league.value,
                                (page || 0) * (pageSize || 25),
                                pageSize || 25,
                                token
                              );
                            }
                          }}
                        >
                          <ClearIcon></ClearIcon>
                        </IconButton>
                      </Tooltip>
                    ) : undefined;

                const thirdAction =
                  league.value.status !== LeagueStatus.Finished &&
                    m.status === MatchStatus.Confirmed && m.date <= new Date() &&
                    (m.firstPlayer.id === player.value.id ||
                      m.secondPlayer.id === player.value.id) ? (
                      <Tooltip title="Podaj wynik meczu" key="3">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setCurrentMatch(m);
                            setMatchResultDialogOpen(true);
                          }}
                        >
                          <PostAddIcon></PostAddIcon>
                        </IconButton>
                      </Tooltip>
                    ) : undefined;

                const forthAction = (<Tooltip title="Dane przeciwnika" key="4">
                  <IconButton
                    size="small"
                    onClick={(evt) => getPlayerDetails(m.firstPlayer.id === player.value.id ? m.secondPlayer.id : m.firstPlayer.id, evt.currentTarget)}
                  >
                    <PermContactCalendarIcon fontSize="inherit"></PermContactCalendarIcon>
                  </IconButton>
                </Tooltip>)

                let actions: JSX.Element[] = [];
                if (!!firstAction) {
                  actions = [...actions, firstAction];
                }
                if (!!secondAction) {
                  actions = [...actions, secondAction];
                }
                if (!!thirdAction) {
                  actions = [...actions, thirdAction];
                }
                if (!!forthAction) {
                  actions = [...actions, forthAction];
                }


                return <>{actions}</>;
              }}
            ></MatchesTable>
          </>
        ) : (
          <></>
        )}
      <Button startIcon={<ChevronLeftIcon />} onClick={() => window.history.back()}>Powrót</Button>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    league: usersAssignedLeague(state),
    player: currentPlayer(state),
    matches: state.matches.currentLeaguePlayersMatchesPage,
    clubs: state.clubs.clubs
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadLeagueDetails: (year: string, id: string) =>
      dispatch(loadLeagueDetails({ year, id })),
    loadPlayersMatches: (
      year: string,
      id: string,
      skip: number,
      take: number,
      token: string
    ) => dispatch(getPlayersMatches({ year, id, skip, take, token })),
    confirmMatch: (
      match: MatchHeader,
      league: LeagueWithCurrentUserAssignment,
      skip: number,
      take: number,
      token: string
    ) =>
      dispatch(
        confirmMatch({
          skip,
          take,
          token,
          matchId: match.id,
          leagueId: league.id,
          year: league.year
        })
      ),
    cancelMatch: (
      match: MatchHeader,
      league: LeagueWithCurrentUserAssignment,
      skip: number,
      take: number,
      token: string
    ) =>
      dispatch(
        cancelMatch({
          skip,
          take,
          token,
          matchId: match.id,
          leagueId: league.id,
          year: league.year
        })
      ),
    submitResult: (
      match: MatchHeader,
      league: LeagueWithCurrentUserAssignment,
      sets: Set[],
      skip: number,
      take: number,
      token: string
    ) =>
      dispatch(
        submitMatchResult({
          leagueId: league.id,
          matchId: match.id,
          sets,
          skip,
          take,
          token,
          year: league.year
        })
      )
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyMatchesPage)
);
