import { PlayerDescriptor, Clubs } from "../leagues/models";

export interface Match {
    date: Date;
    player: PlayerDescriptor;
    oponent: PlayerDescriptor;
    club: Clubs;
    result?: Set[];
    status?: MatchStatus;
    createdBy?: PlayerDescriptor;
    id?: string;
    description?:string;
}

export enum MatchStatus {
    New = 0, 
    Confirmed = 1, 
    Cancelled = 2, 
    Finished = 3,

    InProcess = 100,
}

export interface Score {
    firstPlayer: number;
    secondPlayer: number;
}

export interface Set extends Score {
    tieBreak?: Score;
}

export interface MatchHeader {
    id: string;
    firstPlayer: PlayerDescriptor;
    secondPlayer: PlayerDescriptor;
    date: Date;
    club: Clubs;
    isOponentRetired: boolean;
    score?: Set[];
    status?: MatchStatus;
    createdBy?: PlayerDescriptor;
    league: string;
    description?:string;
}

export interface PlayerMatch extends MatchHeader {
    playerId: string;
    isPlayerWinner: boolean;
}